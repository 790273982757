<template>
<div class="container1150">
<header><strong>旋流沉砂隔油池</strong></header>
<main>
<p>
                旋流沉砂隔油井是针对合流制污水 ( CSO )
                和重度面凅污染控制而推出的旋流沉砂去油分离器。经过我们的优化设计，利用旋流来分
                离地表径流中的沉淀物、悬浮物和油脂，并降低其水头损失。雨水卫士非常适合用鱼合流制雨污水、农贸市场、汽车修理厂、工业区、
                物流园等重度污染区域的雨水净化
            </p>

<h2>产品特性</h2>
<p>● · 高效的沉砂、 去油一体化设计</p>
<p>
                ·
                当地表径流通过切线进水口进入旋流沉砂隔油井内，其沿着外环外壁形成旋
                流（如红线所示）。油脂、垃圾和悬浮物被捕获在图中苗色区域内。而沉淀物随旋流到达中心区，由千流速降低而沉淀到沉砂区。底部挡板和中心区将改变水流方向，使得水流在内柱和外柱之间形成较长的停留时间，有利千沉淀效果
            </p>
<div class="img-list">
<div class="img1"></div>
<div class="img2"></div>
</div>
<p>● ·优化流场设计，减小水头损失，对排水影响降至最小</p>
<p>● ·便捷的安装与维护</p>
<p>
                ·
                旋流沉砂隔油井可安装于我们提供的PE成品也可安装在现有的混凝土（砌砖）井中
            </p>
<div class="img3"></div>
<div class="img4"></div>
<div class="img5"></div>
</main>
</div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .img1 {
            // width: 100%;
            width: 550px;
            height: 400px;
            margin: 10px auto;
            background-image: url("/img/product/2-1.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img-list {
            display: flex;
            width: 100%;
            .img1 {
                // width: 100%;
                width: 600px;
                height: 500px;
                margin: 10px auto;
                background-image: url("/img/product/2-1.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img2 {
                // width: 100%;
                width: 550px;
                height: 500px;
                margin: 10px auto;
                background-image: url("/img/product/2-2.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        .img3 {
            width: 900px;
            height: 800px;
            margin: 10px auto;
            background-image: url("/img/product/2-3.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img4 {
            width: 1000px;
            height: 320px;
            margin: 10px;
            background-image: url("/img/product/2-4.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img5 {
            width: 900px;
            height: 600px;
            margin: 10px auto;
            background-image: url("/img/product/2-5.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>